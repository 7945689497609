import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const TrashCanIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M11.5 4C11.5 3.17157 10.8284 2.5 10 2.5C9.17157 2.5 8.5 3.17157 8.5 4H7.5C7.5 2.61929 8.61929 1.5 10 1.5C11.3807 1.5 12.5 2.61929 12.5 4H17C17.2761 4 17.5 4.22386 17.5 4.5C17.5 4.77614 17.2761 5 17 5H16.446L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.553 5H3C2.75454 5 2.55039 4.82312 2.50806 4.58988L2.5 4.5C2.5 4.22386 2.72386 4 3 4H11.5ZM15.438 5H4.561L5.84347 16.1146C5.90169 16.6192 6.32894 17 6.83688 17H13.1631C13.6711 17 14.0983 16.6192 14.1565 16.1146L15.438 5ZM8.5 7.5C8.74546 7.5 8.94961 7.65477 8.99194 7.85886L9 7.9375V14.0625C9 14.3041 8.77614 14.5 8.5 14.5C8.25454 14.5 8.05039 14.3452 8.00806 14.1411L8 14.0625V7.9375C8 7.69588 8.22386 7.5 8.5 7.5ZM11.5 7.5C11.7455 7.5 11.9496 7.65477 11.9919 7.85886L12 7.9375V14.0625C12 14.3041 11.7761 14.5 11.5 14.5C11.2545 14.5 11.0504 14.3452 11.0081 14.1411L11 14.0625V7.9375C11 7.69588 11.2239 7.5 11.5 7.5Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M10 1.25C11.4346 1.25 12.6126 2.34848 12.7388 3.75019L17 3.75C17.4142 3.75 17.75 4.08579 17.75 4.5C17.75 4.8797 17.4678 5.19349 17.1018 5.24315L17 5.25H16.417L15.1499 16.2292C15.0335 17.2384 14.179 18 13.1631 18H6.83688C5.821 18 4.9665 17.2384 4.85006 16.2292L3.582 5.25H3C2.6203 5.25 2.30651 4.96785 2.25685 4.60177L2.25 4.5C2.25 4.1203 2.53215 3.80651 2.89823 3.75685L3 3.75L7.26119 3.75019C7.38741 2.34848 8.56542 1.25 10 1.25ZM8.5 7.5C8.25454 7.5 8.05039 7.65477 8.00806 7.85886L8 7.9375V14.0625L8.00806 14.1411C8.05039 14.3452 8.25454 14.5 8.5 14.5C8.74546 14.5 8.94961 14.3452 8.99194 14.1411L9 14.0625V7.9375L8.99194 7.85886C8.94961 7.65477 8.74546 7.5 8.5 7.5ZM11.5 7.5C11.2545 7.5 11.0504 7.65477 11.0081 7.85886L11 7.9375V14.0625L11.0081 14.1411C11.0504 14.3452 11.2545 14.5 11.5 14.5C11.7455 14.5 11.9496 14.3452 11.9919 14.1411L12 14.0625V7.9375L11.9919 7.85886C11.9496 7.65477 11.7455 7.5 11.5 7.5ZM10 2.75C9.39524 2.75 8.89079 3.17947 8.77499 3.75005H11.225C11.1092 3.17947 10.6048 2.75 10 2.75Z"
      />
    </svg>
  ),
  displayName: 'TrashCanIcon',
});
